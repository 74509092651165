<template>
  <div class="output-preview">
    <PresentationOutputPreview
      v-if="isPresentation"
      ref="presentation-preview"
      :output="output"
      :loading="loading"
      :error="error"
      :slide-regenerating="slideRegenerating"
      v-on="{
        ...$listeners
      }"
    />
    <DocumentOutputPreview
      v-else
      ref="document-preview"
      :output="output"
      :loading="loading"
      :error="error"
      v-on="{
        ...$listeners
      }"
    />
  </div>
</template>

<script>
import DocumentOutputPreview from './preview/DocumentOutputPreview.vue'
import PresentationOutputPreview from './preview/PresentationOutputPreview.vue'

export default {
  name: 'MeetingOutputPreview',
  components: {
    DocumentOutputPreview,
    PresentationOutputPreview
  },
  props: {
    output: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    slideRegenerating: {
      type: Number,
      default: -1
    }
  },
  computed: {
    type() {
      return this.output?.tool?.type || ''
    },
    isPresentation() {
      return this.type === 'presentation'
    }
  }
}
</script>

<style lang="scss" scoped>
.output-preview {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
}
</style>
