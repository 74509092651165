var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "output-preview" },
    [
      _vm.isPresentation
        ? _c(
            "PresentationOutputPreview",
            _vm._g(
              {
                ref: "presentation-preview",
                attrs: {
                  output: _vm.output,
                  loading: _vm.loading,
                  error: _vm.error,
                  "slide-regenerating": _vm.slideRegenerating
                }
              },
              Object.assign({}, _vm.$listeners)
            )
          )
        : _c(
            "DocumentOutputPreview",
            _vm._g(
              {
                ref: "document-preview",
                attrs: {
                  output: _vm.output,
                  loading: _vm.loading,
                  error: _vm.error
                }
              },
              Object.assign({}, _vm.$listeners)
            )
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }