<template>
  <b-modal
    active
    :can-cancel="loading ? false : ['escape', 'x', 'outside']"
    @close="close"
  >
    <div class="autocomplete-wrapper" :class="{ error }">
      <div
        ref="autocompleteloading"
        class="autocomplete"
        @scroll="checkAutoScroll"
      >
        <div class="autocomplete-header">
          <div class="autocomplete-header-animation-wrapper">
            <div class="autocomplete-header-animation-glow one"></div>
            <div class="autocomplete-header-animation-glow two"></div>
            <div class="autocomplete-header-animation-glow three"></div>
            <Animation
              name="logo-wink"
              :background-color="error ? '#303032' : '#115efb'"
              class="autocomplete-header-animation"
            />
          </div>
          <p class="autocomplete-header-title">
            {{ error ? 'Something went wrong' : "Hang tight, I'm on it!" }}
          </p>
        </div>
        <div v-if="!error" class="autocomplete-content">
          <div
            v-for="(block, idx) in visibleStatus"
            :key="`autocomplete-status-block-${idx}`"
            class="autocomplete-content-block"
          >
            <div class="autocomplete-content-block-header">
              <p class="autocomplete-content-block-header-title">
                {{ block.title }}
              </p>
              <img
                v-if="!blockLoaded(block, idx)"
                src="@/assets/icons/spinner.svg"
                alt=""
                class="autocomplete-content-block-header-loading"
              />
              <img
                v-else
                src="@/assets/icons/check-circle-filled.svg"
                alt=""
                class="autocomplete-content-block-header-check"
              />
            </div>
            <div
              v-if="block.items && block.items.length"
              class="autocomplete-content-block-list"
            >
              <div
                v-for="(item, itemIdx) in block.items"
                :key="`autocomplete-status-block-item-${itemIdx}`"
                class="autocomplete-content-block-list-item"
              >
                <div class="autocomplete-content-block-list-item-header">
                  <Avatar
                    v-if="item.image"
                    :user="{ avatar: item.image }"
                    size="xs"
                  />
                  <p class="autocomplete-content-block-list-item-header-title">
                    {{ item.title }}
                  </p>
                  <img
                    v-if="item.is_available === null"
                    src="@/assets/icons/spinner.svg"
                    alt=""
                    class="autocomplete-content-block-list-item-header-loading"
                  />
                  <img
                    v-else-if="item.is_available"
                    src="@/assets/icons/check-circle-filled.svg"
                    alt=""
                    class="autocomplete-content-block-list-item-header-check"
                  />
                  <img
                    v-else
                    src="@/assets/icons/close-circle-filled.svg"
                    alt=""
                    class="autocomplete-content-block-list-item-header-check"
                  />
                </div>
              </div>
            </div>
            <div
              v-else-if="block.items && !block.items.length"
              class="autocomplete-content-block-list-item"
            >
              <p class="autocomplete-content-block-list-item-header-title">
                None
              </p>
            </div>
          </div>
        </div>
        <div v-if="!error" class="autocomplete-padding"></div>
      </div>
      <div v-if="error" class="autocomplete-footer">
        <span>We couldn't finish setting up your meeting</span>
        <Button text="Retry" icon="refresh" type="light-solid" @click="retry" />
      </div>
      <div v-else-if="!completed" class="autocomplete-footer">
        <span>This could take 1-3 minutes</span>
        <span>{{ minutes }}m {{ seconds }}s</span>
      </div>
      <div v-else class="autocomplete-footer">
        <span>Meeting preparations ready! 🎉</span>
        <Button text="Let's go" type="light-solid" @click="close" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'
import Animation from '@c/library/Animation.vue'
import { getAutocompleteInsights } from '@/services/meetingService'

export default {
  name: 'MeetingAutocompleteLoading',
  components: {
    Button,
    Avatar,
    Animation
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    status: [],
    visibleStatus: [],
    pollInterval: 2000,
    poller: undefined,
    autoscroll: true,
    itemAdder: undefined,
    checkAvailable: {},
    closeToast: undefined,
    count: 0,
    counter: undefined
  }),
  computed: {
    completed() {
      return (
        !this.loading &&
        this.visibleStatus.length === this.status.length &&
        this.visibleStatus.every((s, idx) => this.blockLoaded(s, idx))
      )
    },
    minutes() {
      const m = Math.floor(this.count / 60)
      return m < 10 ? `${m}` : m
    },
    seconds() {
      const s = this.count % 60
      return s < 10 ? `0${s}` : s
    }
  },
  created() {
    this.poller = setInterval(this.poll, this.pollInterval)
    this.counter = setInterval(() => {
      if (this.completed || this.error) clearInterval(this.counter)
      else this.count++
    }, 1000)
  },
  beforeDestroy() {
    this.clearAllIntervals()
  },
  methods: {
    clearAllIntervals() {
      clearInterval(this.poller)
      clearInterval(this.counter)
      clearInterval(this.itemAdder)
    },
    async poll() {
      if (this.completed || this.error) {
        clearInterval(this.poller)
        return
      }
      try {
        this.status = await getAutocompleteInsights({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid
        })
        this.startAddingItems()
      } catch (e) {
        this.$console.debug('Error loading autocomplete insights', e)
        this.$toast.error(e, 'loading meeting completion insights')
        clearInterval(this.poller)
      }
    },
    scrollToBottom() {
      this.$refs.autocompleteloading.scrollTo({
        top: this.$refs.autocompleteloading.scrollHeight,
        behavior: 'smooth'
      })
    },
    blockLoaded(block, idx) {
      return (
        block.items !== null &&
        block.items?.length === this.status[idx]?.items?.length &&
        (block.items || []).every((i) => i.is_available !== null)
      )
    },
    checkAutoScroll() {
      // if user scrolls up, disable autoscroll
      // if they scroll to the bottom, re-enable autoscroll
      this.autoscroll =
        this.$refs.autocompleteloading.scrollTop +
          this.$refs.autocompleteloading.clientHeight >=
        this.$refs.autocompleteloading.scrollHeight - 50
    },
    close() {
      this.$emit('close')
      if (this.closeToast) this.closeToast.close()
    },
    retry() {
      this.$emit('retry')
    },
    startAddingItems() {
      if (this.itemAdder) return
      this.itemAdder = setInterval(() => {
        if (!this.addNextItem() || this.error || this.completed) {
          clearInterval(this.itemAdder)
          this.itemAdder = undefined
        }
      }, 1000)
    },
    addNextItem() {
      const finish = (toReturn = true) => {
        this.checkAvailabilities()
        if (this.autoscroll) this.$nextTick(this.scrollToBottom)
        return toReturn
      }
      const idx = this.visibleStatus.length - 1
      if (this.visibleStatus[idx]?.items === null && this.status[idx]?.items) {
        this.visibleStatus[idx].items = this.status[idx].items.length
          ? [this.status[idx].items[0]]
          : []
        if (this.status[idx].items[0]?.is_available === null)
          this.checkAvailable[idx] = [0]
        return finish()
      } else if (
        this.status[idx]?.items?.length > this.visibleStatus[idx]?.items?.length
      ) {
        const newIdx = this.visibleStatus[idx].items.length
        this.visibleStatus[idx].items.push(this.status[idx].items[newIdx])
        if (this.status[idx].items[newIdx]?.is_available === null)
          this.checkAvailable[idx] = [
            ...(this.checkAvailable[idx] || []),
            newIdx
          ]
        return finish()
      } else if (this.status.length > this.visibleStatus.length) {
        this.visibleStatus.push({
          ...this.status[this.visibleStatus.length],
          items: this.status[this.visibleStatus.length].items
            ? this.status[this.visibleStatus.length].items?.length
              ? [this.status[this.visibleStatus.length].items[0]]
              : []
            : null
        })
        if (
          this.status[this.visibleStatus.length - 1]?.items?.[0]
            ?.is_available === null
        )
          this.checkAvailable[this.visibleStatus.length - 1] = [0]
        return finish()
      }
      return finish(false)
    },
    checkAvailabilities() {
      Object.keys(this.checkAvailable).forEach((idx) => {
        this.checkAvailable[idx].forEach((i) => {
          if (this.status[idx]?.items?.[i]?.is_available !== null) {
            this.visibleStatus[idx].items[i].is_available =
              this.status[idx].items[i].is_available
            this.checkAvailable[idx] = this.checkAvailable[idx].filter(
              (ci) => ci !== i
            )
            if (!this.checkAvailable[idx].length)
              delete this.checkAvailable[idx]
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.autocomplete {
  overflow: hidden;
  height: 75vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &-wrapper {
    background: white;
    border-radius: 8px;
    width: min(40rem, 95vw);

    &.error {
      & .autocomplete {
        height: fit-content;
      }

      & .autocomplete-footer {
        background: #303032;
      }
    }
  }

  &-header {
    padding: 3rem 1.5rem 2.5rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1rem;

    &-animation {
      background: $primary;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 999rem;
      overflow: hidden;

      &-wrapper {
        position: relative;
        width: 5rem;
        height: 5rem;
        border-radius: 999rem;
      }

      &-glow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 999rem;
        background: $primary;

        &.one {
          filter: blur(1rem) opacity(0.25);
          background: rgb(218, 66, 212);
        }
        &.two {
          filter: blur(2.5rem) saturate(1.5) opacity(0.75);
          background: rgba(218, 66, 212, 0.45);
        }
        &.three {
          filter: blur(4rem) saturate(8);
        }
      }
    }

    &-title {
      font-size: 2rem;
      font-weight: 700;
    }
  }

  &-content {
    padding: 1.5rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 2.5rem;

    &-block {
      display: flex;
      flex-flow: column nowrap;
      gap: 1.25rem;

      &-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;

        &-title {
          font-size: 1.25rem;
          font-weight: 700;
        }

        &-loading {
          height: 1.5rem;
          animation: loading 1s infinite;
        }

        &-check {
          height: 1.5rem;
          filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
            saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
        }
      }

      &-list {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;

        &-item {
          padding: 1rem;
          border-radius: 8px;
          display: flex;
          flex-flow: column nowrap;
          gap: 1rem;
          background: rgba(#98baff, 0.25);
          animation: fly-in 500ms;

          &-header {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 0.25rem;

            &-title {
              font-size: 1rem;
              font-weight: 600;
              margin-right: auto;
            }

            &-loading {
              height: 1.2rem;
              animation: loading 1s infinite;
            }

            &-check {
              height: 1.2rem;
              filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
                saturate(2915%) hue-rotate(217deg) brightness(97%)
                contrast(103%);
            }
          }
        }
      }
    }
  }

  &-closing {
    padding: 1.5rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    align-items: center;

    &-text {
      font-size: 1.25rem;
      font-weight: 600;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
    }
  }

  &-padding {
    height: 5rem;
  }

  &-footer {
    background: $primary;
    color: white;
    padding: 0 1.5rem;
    height: 4rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    font-weight: 600;
    font-size: 1.15rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fly-in {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
