<template>
  <div class="meeting-outputs">
    <MeetingOutputsHeader :meeting="meeting" @edit="handleMeetingEdit" />
    <div
      ref="meetingoutputs"
      class="meeting-outputs-content"
      :style="maxHeightStyle"
    >
      <div class="meeting-outputs-overview">
        <MeetingOverviewSidebar
          :key="overviewKey"
          :meeting="meeting"
          :loading="initLoading"
          @select="openDetail"
          @edit="openEdit"
        />
      </div>
      <div class="meeting-outputs-preview">
        <div v-if="initLoading" class="meeting-outputs-loading">
          <b-loading :is-full-page="false" active />
        </div>
        <div
          v-for="output in initLoading ? [] : outputs"
          :key="`output-preview-${outputId(output)}`"
          class="meeting-outputs-preview-tab"
          :class="{
            visible:
              selectedOutput && outputId(selectedOutput) === outputId(output)
          }"
        >
          <MeetingOutputPreview
            :output="output"
            :loading="outputContentLoading.includes(output.uuid)"
            :slide-regenerating="slideRegenerating"
            :error="!!outputContentError[output.uuid]"
            @regenerate="
              (prompt, slide_index) =>
                loadOutputContent(output, true, { prompt, slide_index })
            "
          />
        </div>
      </div>
      <div class="meeting-outputs-outputs">
        <MeetingOutputsSidebar
          :outputs="outputs"
          :selected="selectedOutput"
          :loading="initLoading"
          :content-loading="outputContentLoading"
          :remove-loading="removeLoading"
          @select="selectOutput"
          @export="startExport"
          @add="startAddOutputs"
          @history="showOutputHistory"
          @translate="startOutputTranslate"
          @rename="startOutputRename"
          @delete="removeOutput"
        />
      </div>
    </div>
    <MeetingEditModal
      v-show="!!editModal"
      :visible="!!editModal"
      :meeting="meeting"
      :type="editModal"
      :outputs="outputs"
      :selected="selectedOutput"
      :content-loading="outputContentLoading"
      @detail="(item) => openDetail({ key: editModal }, { item })"
      @close="closeEdit"
      @input="handleMeetingEdit"
      @regenerate="handleRegenerate"
      @suggestions="setMeetingSuggestions"
    />
    <MeetingExportModal
      v-if="exportModal"
      :meeting="meeting"
      :outputs="outputs"
      :export-urls="exportUrls"
      :loading="outputContentLoading"
      @close="() => (exportModal = false)"
    />
    <EditMeeting
      v-if="addModal"
      :visible="addModal"
      :meeting="meeting"
      mode="assets"
      @close="() => (addModal = false)"
      @assets="addOutputs"
    />
    <MeetingOutputTranslateModal
      v-if="translateModal"
      :output="translateModal"
      @close="() => (translateModal = undefined)"
      @translate="finishTranslate"
    />
    <MeetingOutputNameModal
      v-show="!!renameModal"
      :output="renameModal"
      :visible="!!renameModal"
      @close="() => (renameModal = undefined)"
      @rename="finishRename"
    />
    <MeetingAutocompleteLoading
      v-if="showAutocomplete"
      :key="autocompleteKey"
      :meeting="meeting"
      :loading="autocompleteLoading"
      :error="autocompleteError || meetingError"
      @close="() => (showAutocomplete = false)"
      @retry="retryInit"
    />
    <MeetingChangedModal
      v-if="!autocompleteLoading && meeting"
      :meeting="meeting"
      :visible="!!meetingChanged"
      :hide-tabs="meetingChanged === 'offerings' ? ['offerings'] : []"
      @close="() => finishMeetingChanged({})"
      @submit="finishMeetingChanged"
      @suggestions="setMeetingSuggestions"
      @detail="(item, type) => openDetail({ key: type }, { item })"
    />
    <MeetingORIModal
      v-if="oriModal"
      :item="oriModal.item"
      :meeting="meeting"
      :type="oriModal.type"
      @close="() => (oriModal = undefined)"
    />
    <MeetingAccountModal
      v-if="accountModal"
      :meeting="meeting"
      @close="() => (accountModal = false)"
    />
    <MeetingNoteModal
      v-if="noteModal"
      :note="noteModal"
      @close="() => (noteModal = undefined)"
    />
  </div>
</template>

<script>
import EditMeeting from './meeting/EditMeeting.vue'
import MeetingORIModal from './meeting/ori/MeetingORIModal.vue'
import MeetingEditModal from './meeting/MeetingEditModal.vue'
import MeetingNoteModal from './meeting/notes/MeetingNoteModal.vue'
import MeetingExportModal from './meeting/MeetingExportModal.vue'
import MeetingChangedModal from './meeting/MeetingChangedModal.vue'
import MeetingAccountModal from './meeting/account/MeetingAccountModal.vue'
import MeetingOutputsHeader from './meeting/outputs/MeetingOutputsHeader.vue'
import MeetingOutputPreview from './meeting/outputs/MeetingOutputPreview.vue'
import MeetingOutputsSidebar from './meeting/outputs/MeetingOutputsSidebar.vue'
import MeetingOverviewSidebar from './meeting/outputs/MeetingOverviewSidebar.vue'
import MeetingOutputNameModal from './meeting/outputs/MeetingOutputNameModal.vue'
import MeetingAutocompleteLoading from './meeting/outputs/MeetingAutocompleteLoading.vue'
import MeetingOutputTranslateModal from './meeting/outputs/MeetingOutputTranslateModal.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import {
  autocompleteMeeting,
  createMeetingOutput,
  editMeetingOutput,
  getMeeting,
  getMeetingOutput,
  removeMeetingOutput
} from '@/services/meetingService'

export default {
  name: 'MeetingOutputs',
  components: {
    EditMeeting,
    MeetingORIModal,
    MeetingEditModal,
    MeetingNoteModal,
    MeetingExportModal,
    MeetingChangedModal,
    MeetingAccountModal,
    MeetingOutputsHeader,
    MeetingOutputPreview,
    MeetingOutputsSidebar,
    MeetingOverviewSidebar,
    MeetingOutputNameModal,
    MeetingAutocompleteLoading,
    MeetingOutputTranslateModal
  },
  mixins: [MaxHeightMixin],
  data: () => ({
    meeting: undefined,
    meetingLoading: false,
    meetingError: false,
    outputs: [],
    selectedOutput: undefined,
    outputProps: {},
    outputContentLoading: [],
    slideRegenerating: -1,
    outputContentError: {},
    removeLoading: [],
    historyModal: undefined,
    translateModal: undefined,
    renameModal: undefined,
    editModal: '',
    exportModal: false,
    addModal: false,
    autocompleteLoading: false,
    autocompleteError: false,
    showAutocomplete: false,
    autocompleteKey: 0,
    accountModal: false,
    oriModal: undefined,
    noteModal: undefined,
    prepareMeetingChanged: '',
    meetingChanged: '',
    overviewKey: 0
  }),
  computed: {
    initLoading() {
      return this.meetingLoading || this.autocompleteLoading
    },
    exportUrls() {
      return this.outputs.reduce(
        (acc, output) => ({
          ...acc,
          [output.uuid]: output.signed_url
        }),
        {}
      )
    },
    sortedOutputs() {
      const d = (o) =>
        o.date_updated || o.date_created || new Date().toISOString()
      let o = [...this.outputs]
      return o.sort((a, b) => new Date(d(b)) - new Date(d(a)))
    }
  },
  async created() {
    try {
      this.loadOutputProps()
      await this.loadMeeting()
      if (!this.meeting.is_autocompleted) await this.loadAutocomplete()
      if (this.checkMeetingChanged()) return
      this.loadOutputs()
    } catch (e) {
      this.$console.debug('Error in meeting outputs init', e)
    }
  },
  methods: {
    getMaxHeightElement() {
      return this.$refs.meetingoutputs
    },
    outputId(output) {
      return output?.version_id || output?.uuid
    },
    setOutputs(outputs) {
      this.outputs = outputs
      this.meeting.outputs = outputs
    },
    async retryInit() {
      try {
        if (this.meetingError) {
          this.meetingError = false
          await this.loadMeeting()
        }
        if (this.autocompleteError) this.autocompleteError = false
        if (!this.meeting.is_autocompleted) await this.loadAutocomplete()
        this.loadOutputs()
      } catch (e) {
        this.$console.debug('Error in meeting outputs retry', e)
      }
    },
    loadOutputProps() {
      let props = localStorage.getItem('meetingOutputProps')
      props = props ? JSON.parse(props) : {}
      this.outputProps = props
    },
    async loadMeeting() {
      try {
        this.meetingLoading = true
        this.meeting = await getMeeting({
          workspace_id: this.$route.params.workspace_id,
          meeting_id: this.$route.params.meeting_id
        })
      } catch (e) {
        this.$console.debug('Error when loading meeting info', e)
        this.$toast.error(e, 'loading meeting info')
        this.meetingError = true
        throw e
      } finally {
        this.meetingLoading = false
      }
    },
    async loadAutocomplete() {
      try {
        this.autocompleteLoading = true
        this.autocompleteKey++
        this.showAutocomplete = true
        const meeting = await autocompleteMeeting({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid
        })
        this.meeting = meeting
      } catch (e) {
        this.$console.debug('Error when completing meeting', e)
        this.$toast.error(e, 'completing meeting')
        this.autocompleteError = true
        throw e
      } finally {
        this.autocompleteLoading = false
      }
    },
    checkMeetingChanged() {
      if (localStorage.getItem('meetingOutputStoryChange')) {
        localStorage.removeItem('meetingOutputStoryChange')
        this.meetingChanged = 'story'
      }
      return !!this.meetingChanged
    },
    finishMeetingChanged({ meeting, outputs }) {
      if (meeting) this.meeting = meeting
      if (outputs?.length) this.handleRegenerate(outputs)
      if (this.meetingChanged === 'story') this.loadOutputs()
      this.meetingChanged = ''
    },
    loadOutputs() {
      let newTemplates = localStorage.getItem('meetingOutputTemplates')
      newTemplates = newTemplates ? JSON.parse(newTemplates) : []
      if (newTemplates.length) {
        this.outputs = newTemplates.map((t) => ({
          ...t,
          date_created: new Date().toISOString(),
          date_updated: new Date().toISOString(),
          tool: { ...t }
        }))
        this.selectOutput(this.sortedOutputs[0], false)
        this.outputs.forEach((output) => this.loadOutputContent(output))
        localStorage.removeItem('meetingOutputTemplates')
      }

      this.outputs = [...this.outputs, ...(this.meeting.outputs || [])]
      if (!this.selectedOutput && this.outputs.length) {
        if (this.$route.query.selected)
          this.selectOutput(
            this.outputs.find(
              (o) => o.version_id === this.$route.query.selected
            )
          )
        else this.selectOutput(this.sortedOutputs[0])
      }
    },
    async loadOutputContent(
      output,
      regenerate = false,
      { prompt, props, slide_index } = {}
    ) {
      try {
        if (slide_index === undefined)
          this.outputContentLoading = [
            ...this.outputContentLoading,
            output.uuid
          ]
        else this.slideRegenerating = slide_index
        const outputFunction = regenerate
          ? editMeetingOutput
          : output.uuid === output.tool.uuid
          ? createMeetingOutput
          : getMeetingOutput
        const res = await outputFunction({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid,
          ...(regenerate || output.uuid !== output.tool.uuid
            ? { output_id: output.uuid, prompt, settings: props, slide_index }
            : {
                tool_id: output?.tool?.uuid,
                settings: this.outputProps?.[output?.tool?.uuid] || {}
              })
        })
        this.setOutputs(
          this.outputs.map((o) => (o.uuid === output.uuid ? res : o))
        )
        if (this.outputId(this.selectedOutput) === this.outputId(output)) {
          this.selectOutput(res, false)
        }
      } catch (e) {
        this.$console.debug('Error while generating output', e)
        this.outputContentError = {
          ...this.outputContentError,
          [output.uuid]:
            typeof e === 'string' && !e.includes('Server Error (500)')
              ? e
              : 'error'
        }
      } finally {
        this.outputContentLoading = this.outputContentLoading.filter(
          (id) => id !== output.uuid
        )
        this.slideRegenerating = -1
      }
    },
    selectOutput(output, load = true) {
      this.selectedOutput = output
      if (
        !(output.presentation || output.document) &&
        load &&
        !this.outputContentLoading.includes(output.uuid)
      )
        this.loadOutputContent(output)
      if (
        !this.$route.query.selected ||
        this.$route.query.selected !== this.outputId(output)
      )
        this.$router.replace({
          query: {
            ...this.$route.query,
            selected: this.outputId(output)
          }
        })
    },
    openDetail(block, item) {
      if (block.key === 'people') {
        window.open(item.url, '_blank')
      } else if (block.key === 'account') {
        if (this.meeting?.account?.organisation?.insights?.length)
          this.accountModal = true
        else window.open(item.url, '_blank')
      } else if (block.key === 'notes') {
        this.noteModal = item.item
      } else {
        this.oriModal = {
          item: item.item,
          type: block.key
        }
      }
    },
    openEdit(block) {
      this.editModal = block.key
    },
    closeEdit() {
      this.editModal = ''
      if (this.prepareMeetingChanged) {
        this.meetingChanged = this.prepareMeetingChanged
        this.prepareMeetingChanged = ''
      }
    },
    handleMeetingEdit(meeting) {
      if (['people', 'notes', 'offerings'].includes(this.editModal))
        this.prepareMeetingChanged = this.editModal
      this.meeting = meeting
    },
    handleRegenerate(outputs) {
      outputs.forEach(async (o) => {
        let output = this.outputs.find((oo) => oo.uuid === o.uuid)
        if (
          !(output.presentation || output.document) &&
          !this.outputContentLoading.includes(output.uuid)
        ) {
          await this.loadOutputContent(o)
          output = this.outputs.find((oo) => oo.uuid === o.uuid)
        }
        this.loadOutputContent(output, true, {
          ...(output.tool?.type === 'document'
            ? { prompt: output.document.prompt }
            : {})
        })
      })
    },
    startExport() {
      Object.keys(this.exportUrls).forEach((e) => {
        if (!this.exportUrls[e])
          this.loadOutputContent(this.outputs.find((o) => o.uuid === e))
      })
      this.exportModal = true
    },
    startAddOutputs() {
      this.addModal = true
    },
    addOutputs({ templates, settings }) {
      this.addModal = false
      this.outputProps = {
        ...this.outputProps,
        ...(settings || {})
      }
      localStorage.setItem(
        'meetingOutputProps',
        JSON.stringify(this.outputProps)
      )
      const newTemplates = templates.map((t) => ({
        ...t,
        date_created: new Date().toISOString(),
        date_updated: new Date().toISOString(),
        tool: { ...t }
      }))
      this.setOutputs([...newTemplates, ...this.outputs])
      newTemplates.forEach((output) => this.loadOutputContent(output))
      this.selectOutput(this.sortedOutputs[0], false)
    },
    showOutputHistory(output) {
      this.historyModal = output
    },
    setMeetingSuggestions(suggestions, type) {
      this.meeting.suggestions = {
        ...this.meeting.suggestions,
        [type]: suggestions
      }
      this.overviewKey++
    },
    startOutputTranslate(output) {
      this.translateModal = output
    },
    startOutputRename(output) {
      this.renameModal = output
    },
    finishRename(output) {
      this.setOutputs(
        this.outputs.map((o) => (o.uuid === output.uuid ? output : o))
      )
      if (this.outputId(this.selectedOutput) === this.outputId(output)) {
        this.selectOutput(output, false)
      }
      this.renameModal = undefined
    },
    finishTranslate(language) {
      this.loadOutputContent(this.translateModal, true, {
        ...(this.translateModal?.document?.prompt
          ? { prompt: this.translateModal?.document?.prompt }
          : {}),
        props: { language }
      })
      this.translateModal = undefined
    },
    async removeOutput(output) {
      try {
        this.removeLoading = [...this.removeLoading, output.uuid]
        await removeMeetingOutput({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid,
          output_id: output.uuid
        })
        this.setOutputs(this.outputs.filter((o) => o.uuid !== output.uuid))
        this.meeting.outputs = this.outputs
        if (
          this.outputId(this.selectedOutput) === this.outputId(output) &&
          this.outputs.length
        ) {
          this.selectOutput(this.sortedOutputs[0])
        }
      } catch (e) {
        this.$console.debug('Error while removing output', e)
        this.$toast.error(e, 'removing output')
      } finally {
        this.removeLoading = this.removeLoading.filter(
          (id) => id !== output.uuid
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-outputs {
  &-content {
    display: flex;
    flex-flow: row nowrap;
    overflow-y: auto;
  }

  &-overview {
    flex: 1;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }

  &-loading {
    position: relative;
    min-height: 5rem;
  }

  &-preview {
    flex: 3;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    border-left: 1px solid rgba(#000, 0.08);
    border-right: 1px solid rgba(#000, 0.08);

    &-tab {
      display: none;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;

      &.visible {
        display: block;
      }
    }
  }

  &-outputs {
    flex: 1;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}
</style>
